<template>
  <div class="container">
    <h4 class="text-center my-3">Направление подготовки преподавателей</h4>
    <div v-if="data.teachers.length > 0">
      <DataTable :value="data.teachers" :paginator="true" :rows="20"
                 :rowsPerPageOptions="[10, 20, 50, 100, 200]"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 v-model:filters="filters" filterDisplay="menu"
                 :globalFilterFields="['fio']"
                 responsiveLayout="scroll">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск преподавателя"/>
            </span>
          </div>
        </template>
        <template #empty>
          Преподаватель не найден
        </template>
        <template #loading>
          Идет поиск Преподавателей. Пожалуйста подождите.
        </template>
        <Column field="fio" header="ФИО преподавателя"></Column>
        <Column header="Список направлений подготовки">
          <template #body="{data}">
            <div v-if="data.directions">
              <div v-if="data.directions.length > 0">
                <ul class="p-0 m-0">
                  <li class="d-flex align-items-center my-2" v-for="(item, index) in data.directions" :key="index">
                    <span>{{ item.direction_name }}</span>
                    <Button :disabled="buttonLoading" buttonLoading label="Удалить" class="p-button-danger mx-2" @click="deletePpsDirection(item.pps_training_direction_id)"/>
                    <Button :disabled="buttonLoading" label="Изменить" @click="openPpsDirectionEditModal(data.user_id, item.direction_id)"/>
                  </li>
                </ul>
              </div>
              <div v-else>
                <p>У преподавателя нет направлений подготовки </p>
              </div>
              <Button :disabled="buttonLoading" label="Создать" @click="openPpsDirectionCreateModal(data.user_id)"/>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <Dialog v-model:visible="ppsDirectionListVisible" modal :closable="false"
          :header="`Список направлений подготовки преподавателя ${selectedTeacher.fio}`" :style="{ width: '70wv' }">
    <div v-for="(item, index) in directionData" class="row" :key="index">
      <div class="col-4 d-flex align-items-center">
        Направление подготовки <span v-if="activeModalMethod === 'create'">№{{index+1}}</span>
      </div>
      <div class="col-md-8 col-12">
        <Dropdown v-model="item.direction_id" :options="item.direction_options"
                  :filter="true" @filter="filter($event.value, index)" placeholder="Код, наименование"
                  class="col-12" optionValue="direction_id" optionLabel="direction_name"/>
      </div>
    </div>
    <Button v-if="activeModalMethod === 'create'" class="mt-3" label="Создать" @click="createDirection(selectedTeacher.user_id)"/>
    <template #footer>
      <Button icon="pi pi-times" label="Закрыть" class="p-button-danger"
              aria-label="Close" @click="closePpsDirectionListModal"/>
      <Button :disabled="buttonLoading" v-if="activeModalMethod === 'create'" icon="pi pi-check" label="Создать"
              @click="createDirections"/>
      <Button :disabled="buttonLoading" v-if="activeModalMethod === 'edit'" icon="pi pi-check" label="Обновить"
              @click="updateDirection"/>
    </template>
  </Dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "PpsTeachingTrainingDirection",
  data(){
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS},
        'fio': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      ppsDirectionListVisible: false,
      selectedTeacher:{},
      directionData: [],
      activeModalMethod: '',
      buttonLoading: false,
      allDirections: []
    }
  },
  computed:{
    ...mapState('departmentPpsTeachingTrainingDirection', ['data']),
  },
  methods:{
    ...mapActions('departmentPpsTeachingTrainingDirection', ['GET_DEPARTMENT_ACTIVE_TEACHERS', 'DELETE_DIRECTION',
                  'SEARCH_DIRECTIONS', 'CREATE_DIRECTION', 'UPDATE_DIRECTION']),
    createDirection(teacher_id){
      this.directionData.push({
        direction_id: null,
        user_id: teacher_id,
        direction_options: [],
        id: null
      })
    },

    async updateDirection(){
      this.buttonLoading = true
      let updateRes = await this.UPDATE_DIRECTION(this.directionData[0])
      if (updateRes){
        this.closePpsDirectionListModal()
        await this.GET_DEPARTMENT_ACTIVE_TEACHERS()
      }
      this.buttonLoading = false
    },

    async filter(name, index){
      this.directionData[index].direction_options = await this.SEARCH_DIRECTIONS(name)
    },

    async createDirections(){
      this.buttonLoading = true
      let createRes = await this.CREATE_DIRECTION(this.directionData)
      if (createRes){
        this.closePpsDirectionListModal()
        await this.GET_DEPARTMENT_ACTIVE_TEACHERS()
      }
      this.buttonLoading = false
    },

    openPpsDirectionCreateModal(teacher_id){
      this.selectedTeacher = this.data.teachers.find(i => i.user_id == teacher_id)
      this.ppsDirectionListVisible = true
      this.createDirection(teacher_id)
      this.activeModalMethod = 'create'
    },

    async openPpsDirectionEditModal(teacher_id, direction_id){
      this.buttonLoading = true

      this.selectedTeacher = this.data.teachers.find(i => i.user_id == teacher_id)
      let direction = this.selectedTeacher.directions.find(i => i.direction_id == direction_id)
      this.directionData.push({
        direction_id: direction.direction_id,
        direction_name: direction.direction_name,
        user_id: teacher_id,
        id: direction.pps_training_direction_id,
        direction_options: this.allDirections
      })
      this.ppsDirectionListVisible = true
      this.activeModalMethod = 'edit'
      this.buttonLoading = false
    },

    closePpsDirectionListModal(){
      this.selectedTeacher = {}
      this.ppsDirectionListVisible = false
      this.directionData = []
      this.activeModalMethod = ''
    },

    async deletePpsDirection(direction_id){
      this.buttonLoading = true
      let deleteRes = await this.DELETE_DIRECTION(direction_id)
      if (deleteRes){
        this.$toast.add({severity: 'info', summary: 'Успешно', detail: 'Действие успешно выполнено!', life: 3000});
        this.GET_DEPARTMENT_ACTIVE_TEACHERS()
      }
      this.buttonLoading = false
    }
  },
  async mounted() {
    await this.GET_DEPARTMENT_ACTIVE_TEACHERS();
    this.allDirections = await this.SEARCH_DIRECTIONS('');
  }
}
</script>

<style scoped>

</style>